import { Skeleton } from "@progress/kendo-react-indicators";
import { useSelector } from "react-redux";
import { resource } from "../../common/utils";
import { ApplicationState } from "../../store/configureStore";
import { ContactInformationSkeleton } from "./skeleton/ContactInformationSkeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import "../../styles/ContactInformation.scss";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchObjectInformationAction } from "../../sagas/commonSagas";

library.add(faCamera);

export function ContactInformation() {
    const state = useSelector((state: ApplicationState) => state.common);
    const dispatch = useDispatch();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    useEffect(() => {
        if (id) {
            dispatch(fetchObjectInformationAction(id));
        }
    }, [state.selectedLanguage.id])

    return (
        <div className="contact-information">
            <div className="contact-information__picture">
                {state.objectInfoIsLoading &&
                    <Skeleton shape="rectangle" style={{ width: "360px", height: "216px" }} />
                }
                {state.objectInformation.picture ?
                    <div
                        style={{
                            backgroundImage: `url("data:${state.objectInformation.mimeType};base64,${state.objectInformation.picture}")`,
                            height: "216px",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundColor: "#fafafa",
                            backgroundSize: "cover"
                        }}
                    >
                    </div>
                    :
                    <div
                        className="contact-information__no-image"
                    >
                        <FontAwesomeIcon icon={faCamera} size="5x" />
                    </div>
                }
            </div>
            <div className="contact-information__content">
                <h4>{resource("contacts")}</h4>
                {state.objectInfoIsLoading && <ContactInformationSkeleton />}

                {state.objectInformation && state.objectInformation.persons &&
                    state.objectInformation.persons.map((person, index) => {
                        return (
                            <div className="contact-information__person" key={index}>
                                <span className="person__title">{person.jobTitle}</span>
                                <span>{person.firstName ? `${person.firstName} ${person.lastName}, ${person.company}` : `${person.company}`}</span>
                                <span>{person.phone}</span>
                                <a href={`mailto:${person.email}`} className="person__email">{person.email}</a>
                            </div>
                        );
                    })
                }
                {state.objectInformation.persons && state.objectInformation.persons.length === 0 && <span>{resource("common-no-data")}</span>}
            </div>
        </div>
    )
}