import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface IDocumentRM {
    id: number;
    name: string
    className: string;
    documentType: DocumentType;
    files: IFileInfoRm[];
    links: ILinkRm[];
}

export interface IFileInfoRm {
    id: number;
    name: string;
    contentLength: number;
    mimeType: string;
    extension: string;
    thumbnail: IThumbnailRM;
    idForPage: string;
}

export interface IThumbnailRM {
    content: string;
}

export interface ILinkRm {
    id: number;
    url: string;
    name: string;
}

export enum DocumentType {
    File = 0,
    Link = 1
}

export interface DocumentState {
    documents: IDocumentRM[];
    selectedFilePreview: string;
    isLoading: boolean;
}

const initialState: DocumentState = {
    documents: [],
    selectedFilePreview: "",
    isLoading: true
}

export const documentsSlice = createSlice({
    name: "documents",
    initialState: initialState,
    reducers: {
        setDocuments: (state, action: PayloadAction<IDocumentRM[]>) => {
            state.documents = action.payload;
        },
        setSelectedFilePreview: (state, action: PayloadAction<string>) => {
            state.selectedFilePreview = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
})

export const documentActions = documentsSlice.actions;

export default documentsSlice.reducer;