import React from "react";
import { AdditionalFieldType, ModuleServiceRequestAdditionalField } from "../../store/moduleServiceRequestReducer";
import { ClassificationItem } from "./ClassificationItem";

export function ClassificationItemAdditionalField(props: { additionalField: ModuleServiceRequestAdditionalField }) {
    let value: string | undefined = "";

    if (props.additionalField.type === AdditionalFieldType.Text) {
        value = props.additionalField.textValue;
    } else {
        value = props.additionalField.optionValue?.map(option => option.name).join(', ');
    }

    return <ClassificationItem label={props.additionalField.name} value={value} />
}