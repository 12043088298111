import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../store/configureStore"
import { ServiceRequestsRow } from "./ServiceRequestsRow";
import { resource } from "../../common/utils";
import { Button } from "@progress/kendo-react-buttons";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceRequestsListView } from "../shared/ServiceRequestsListView";

export function ServiceRequestsList() {
    const state = useSelector((state: ApplicationState) => state.serviceRequest);

    const [showAll, setShowAll] = useState(false);

    return (
        <ServiceRequestsListView
            isLoading={state.isLoading}
            serviceRequestCount={state.serviceRequestsView?.length}
        >
            {state.serviceRequestsView && state.serviceRequestsView.length > 0 && !showAll ?
                <>
                    {state.serviceRequestsView.slice(0, 10).map(item =>
                        <ServiceRequestsRow
                            serviceRequest={item}
                            key={item.id}
                        />
                    )}
                    {state.serviceRequestsView.length > 10 &&
                        <div className="service-requests__show-more">
                            <Button
                                title={resource("service-request-more")}
                                fillMode="flat"
                                onClick={() => { setShowAll(!showAll) }}
                            >
                                {resource("service-request-more")}
                                <FontAwesomeIcon icon={faCaretDown} />
                            </Button>
                        </div>
                    }
                </>
                :
                state.serviceRequestsView.map(item =>
                    <ServiceRequestsRow
                        serviceRequest={item}
                        key={item.id}
                    />
                )
            }
        </ServiceRequestsListView>
    )
}
