import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { getBackgroundColor, getServiceRequestDetails, getServiceRequests, getExternalServiceRequestUrl } from "../services/serviceRequestService";
import { commonActions } from "../store/commonReducer";
import { IServiceRequestVM, serviceRequestActions, ServiceRequestPhase } from "../store/serviceRequestReducer";

export const fetchServiceRequestsAction = createAction<string>("FETCH_SERVICEREQUESTS");
export const fetchServiceReqestDetailsAction = createAction<{ id: string | null, langCode: string | null }>("FETCH_SERVICEREQUESTDETAILS");
export const fetchExternalServiceRequestUrlAction = createAction<{ id: string, langCode: number }>("FETCH_EXTERNALSERVICEREQUESTURL");
export const filterServiceRequestsByPhaseAction = createAction<ServiceRequestPhase[]>("FETCH_SERVICEREQUESTSANDFILTERBYPHASE");

export const ServiceRequestSagas = {
    watchServiceRequestsFetch: takeLatest(fetchServiceRequestsAction, serviceRequestsFetchAsync),
    watchServiceRequestsWithPhaseFetch: takeLatest(filterServiceRequestsByPhaseAction, serviceRequestsFilterByPhase),
    watchServiceRequestDetailsFetch: takeLatest(fetchServiceReqestDetailsAction, serviceRequestDetailsFetchAsync),
    watchServiceRequestExternalServiceRequestUrlFetch: takeLatest(fetchExternalServiceRequestUrlAction, serviceRequestFetchExternalServiceRequestUrlAsync)
}

export function* serviceRequestsFetchAsync(action: PayloadAction<string>) {
    yield put(serviceRequestActions.setIsLoading(true));
    yield put(serviceRequestActions.setServiceRequests([]));

    const serviceRequests: IServiceRequestVM[] = yield call(getServiceRequests, action.payload);

    yield put(serviceRequestActions.setServiceRequests(serviceRequests));
    yield put(serviceRequestActions.setServiceRequestsByPhases([ServiceRequestPhase.New, ServiceRequestPhase.UnderProgress]))
    yield put(serviceRequestActions.setIsLoading(false));
}

export function* serviceRequestsFilterByPhase(action: PayloadAction<ServiceRequestPhase[]>) {
    yield put(serviceRequestActions.setServiceRequestsByPhases(action.payload))
}

export function* serviceRequestDetailsFetchAsync(action: PayloadAction<{ id: string | null, langCode: string | null }>) {
    if (!action.payload.id || !action.payload.langCode) {
        yield put(push("/error"));
        return;
    }
    yield put(serviceRequestActions.setIsLoading(true));
    yield put(serviceRequestActions.setSelectedServiceRequest({} as IServiceRequestVM));

    const details: IServiceRequestVM = yield call(getServiceRequestDetails, action.payload.id, action.payload.langCode);
    const color: string = yield call(getBackgroundColor);

    yield put(commonActions.setMainBackground(color));
    yield put(serviceRequestActions.setSelectedServiceRequest(details));
    yield put(serviceRequestActions.setIsLoading(false));
}

export function* serviceRequestFetchExternalServiceRequestUrlAsync(action: PayloadAction<{ id: string, langCode: number }>) {
    yield put(serviceRequestActions.setIsLoading(true));

    const externalServiceRequestUrl: string = yield call(getExternalServiceRequestUrl, action.payload.id, action.payload.langCode);

    yield put(commonActions.setExternalServiceRequestUrl(externalServiceRequestUrl));
    yield put(serviceRequestActions.setIsLoading(false));
}