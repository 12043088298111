import { Button } from "@progress/kendo-react-buttons";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resource } from "../../common/utils";
import { ApplicationState } from "../../store/configureStore";
import { ModuleServiceRequestStatus, moduleServiceRequestActions, ModuleServiceReqestPhaseView } from "../../store/moduleServiceRequestReducer";
import { ServiceRequestPhase, serviceRequestActions, ServiceReqestPhaseView } from "../../store/serviceRequestReducer";

export interface ServiceRequestsViewProps extends PropsWithChildren<any> {
    leaveNewServiceRequest: boolean,
    serviceRequestAccess: boolean,
    isLoading: boolean,
    serviceRequestOpenCount?: number,
    serviceRequestReadyCount?: number,
    onExternalServiceRequestPageClick: MouseEventHandler
}

export const ServiceRequestsView = (props: ServiceRequestsViewProps) => {
    const dispatch = useDispatch();
    const commonState = useSelector((state: ApplicationState) => state.common);
    const state = useSelector((state: ApplicationState) => commonState.isUsingServiceRequestModule ? state.moduleServiceRequest : state.serviceRequest);

    const onPhaseClick = (phases: ServiceRequestPhase[] | ModuleServiceRequestStatus[]) => {
        if (phases.length === 0)
            return;

        if (commonState.isUsingServiceRequestModule) {
            dispatch(moduleServiceRequestActions.setServiceRequestsByPhases(phases as ModuleServiceRequestStatus[]));
        } else {
            dispatch(serviceRequestActions.setServiceRequestsByPhases(phases as ServiceRequestPhase[]));
        }
    }

    const openPhases = commonState.isUsingServiceRequestModule
        ? [ModuleServiceRequestStatus.New, ModuleServiceRequestStatus.InProgress]
        : [ServiceRequestPhase.New, ServiceRequestPhase.UnderProgress];

    const closedPhases = commonState.isUsingServiceRequestModule
        ? [ModuleServiceRequestStatus.Closed]
        : [ServiceRequestPhase.Done];

    const openPhase = commonState.isUsingServiceRequestModule
        ? state.selectedView === ModuleServiceReqestPhaseView.Open
        : state.selectedView === ServiceReqestPhaseView.Open;

    const closedPhase = commonState.isUsingServiceRequestModule
        ? state.selectedView === ModuleServiceReqestPhaseView.Closed
        : state.selectedView === ServiceReqestPhaseView.Done;

    return (
        <div className="service-requests">

            <div className="service-requests__header">
                <h4>{resource("service-request")}</h4>
                {props.leaveNewServiceRequest &&
                    <>
                        <Button
                            themeColor="primary"
                            onClick={props.onExternalServiceRequestPageClick}
                            className={"service-requests__add-button"}
                            disabled={props.isLoading}
                        >
                            {resource("service-request-add")}
                        </Button>
                    </>
                }

            </div>
            <div className={"k-tabstrip k-tabstrip-top"}>
                {props.serviceRequestAccess &&
                    <>
                        <div className="k-tabstrip-items-wrapper k-hstack">
                            <ul className="k-tabstrip-items k-reset" role="tablist">
                                <li aria-selected="true" role="tab" className={openPhase ? "k-item k-active" : "k-item"}>
                                    <span className="k-link" onClick={() => onPhaseClick(openPhases)}>
                                        {resource("service-request-open")}
                                        <span id="openCount" className={openPhase ? "request-count selected-phase k-badge k-badge-md k-badge-solid k-badge-solid-light k-rounded-md" : "request-count k-badge k-badge-md k-badge-solid k-badge-solid-light k-rounded-md"}>{`${props.serviceRequestOpenCount ? props.serviceRequestOpenCount : 0}`}</span>
                                    </span>
                                </li>
                                <li aria-selected="false" role="tab" className={closedPhase ? "k-item k-active" : "k-item"}>
                                    <span className="k-link" onClick={() => onPhaseClick(closedPhases)}>
                                        {resource("service-request-closed")}
                                        <span id="closedCount" className={closedPhase ? "request-count selected-phase k-badge k-badge-md k-badge-solid k-badge-solid-light k-rounded-md" : "request-count k-badge k-badge-md k-badge-solid k-badge-solid-light k-rounded-md"} >{`${props.serviceRequestReadyCount ? props.serviceRequestReadyCount : 0}`}</span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </>

                }
                {props.serviceRequestAccess &&
                    <>
                        {props.children}
                    </>
                }
            </div>
        </div>
    )
}