import { all } from "redux-saga/effects";
import { CommonSagas } from "./commonSagas";
import { DocumentSagas } from "./documentSagas";
import { ModuleServiceRequestSagas } from "./moduleServiceRequestSagas";
import { NoteBoardSagas } from "./noteBoardSagas";
import { ServiceRequestSagas } from "./serviceRequestSagas";

export function* rootSaga() {
    yield all({
        ...CommonSagas,
        ...DocumentSagas,
        ...NoteBoardSagas,
        ...ServiceRequestSagas,
        ...ModuleServiceRequestSagas
    });
}