import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IPortfolioObjectVM, IKeyValuePairVM } from "./commonReducer";

export enum ServiceRequestPhase {
    New = 1,
    UnderProgress = 2,
    Done = 3
}

export enum ServiceReqestPhaseView {
    Open = 1,
    Done = 2
}

export interface IServiceRequestVM {
    id: number;
    request: string;
    phase: ServiceRequestPhase;
    codedObject: IPortfolioObjectVM;
    serviceArea: IKeyValuePairVM;
    adviceType: IKeyValuePairVM;
    createdDate: Date;
    additionalInfoFieldName: string;
    encryptedUrl: string;
}

export interface ServiceRequestState {
    serviceRequests: IServiceRequestVM[];
    serviceRequestsView: IServiceRequestVM[];
    selectedServiceRequest: IServiceRequestVM;
    selectedView: ServiceReqestPhaseView;
    isLoading: boolean;
}

const initialState: ServiceRequestState = {
    serviceRequests: [],
    serviceRequestsView: [],
    selectedView: ServiceReqestPhaseView.Open,
    selectedServiceRequest: {} as IServiceRequestVM,
    isLoading: true
}

export const serviceRequestSlice = createSlice({
    name: "serviceRequest",
    initialState: initialState,
    reducers: {
        setServiceRequests: (state, action: PayloadAction<IServiceRequestVM[]>) => {
            state.serviceRequests = action.payload;
        },
        setServiceRequestsByPhases: (state, action: PayloadAction<ServiceRequestPhase[]>) => {
            const phases = action.payload;
            state.serviceRequestsView = state.serviceRequests?.filter(item => phases.includes(item.phase))
            state.selectedView = phases.includes(ServiceRequestPhase.Done) ? ServiceReqestPhaseView.Done : ServiceReqestPhaseView.Open
        },
        setSelectedServiceRequest: (state, action: PayloadAction<IServiceRequestVM>) => {
            state.selectedServiceRequest = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
})

export const serviceRequestActions = serviceRequestSlice.actions;

export default serviceRequestSlice.reducer;