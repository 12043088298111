import React, { useEffect } from "react";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import internationalizationLoader from "../common/internationalizationloader";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store/configureStore";
import { initLocalization } from "../common/localizationsService";
import { commonActions } from "../store/commonReducer";
import { useState } from "react";

interface ILocalizationProps {
    children: any;
}

export function Localization(props: ILocalizationProps) {
    const state = useSelector((state: ApplicationState) => state.common);
    const dispatch = useDispatch();

    const [selectedKendoLangCode, setSelectedKendoLangCode] = useState("en-GB");
    const [kendoLanguages] = useState(["fi", "de", "da", "ru", "sv", "et", "lt", "lv", "zh", "en-GB", "sv-FI", "ar-AE", "nb"]);

    useEffect(() => {
        if (state.selectedLanguage.code) {
            internationalizationLoader(state.selectedLanguage.code).then(() => {
                initLocalization(state.selectedLanguage.code).then(() => {
                    setLanguageCodeForKendo();

                    dispatch(commonActions.setIsFetching(false));
                });
            });
        }
    }, [state.selectedLanguage])

    const setLanguageCodeForKendo = () => {
        let matchedLanguage = kendoLanguages.find(l => l === state.selectedLanguage.code);

        // HACK: legacy api support, remove after 1 version
        if (state.selectedLanguage.twoLetterISOName === undefined) {
            setSelectedKendoLangCode(state.selectedLanguage.code.substring(0, 2));
        }

        // then with two letter if not found
        if (matchedLanguage == null) {
            matchedLanguage = kendoLanguages.find(l => l === state.selectedLanguage.twoLetterISOName);
        }

        if (matchedLanguage == null) {
            console.error("Unsupported language");
        } else {
            setSelectedKendoLangCode(matchedLanguage);
        }
    }

    return (
        <IntlProvider locale={selectedKendoLangCode}>
            <LocalizationProvider language={selectedKendoLangCode}>
                <>
                    {props.children}
                </>
            </LocalizationProvider>
        </IntlProvider>
    )
}