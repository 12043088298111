import React from "react";
import { IFileInfoRm, ILinkRm } from "../../store/documentReducer";
import { Tooltip } from '@progress/kendo-react-tooltip';

interface DocumentAttachmentProps {
    attachment: IFileInfoRm | ILinkRm;
    onClick: () => void;
}

export function DocumentAttachment(props: DocumentAttachmentProps) {
    function isFile(object): object is IFileInfoRm {
        return "extension" in object;
    }

    const extension = isFile(props.attachment) ? props.attachment.extension.replace(".", "") : "URL";

    const classes = isFile(props.attachment) ? `document-item__file-type-${extension}` : "document-item__link";

    return (
        <div
            className={`document-item other-document ${classes}`}
            key={props.attachment.id}
            onClick={props.onClick}
        >
            <Tooltip
                tooltipStyle={{
                    maxWidth: '300px'
                }}
                showCallout={false}
                anchorElement={"target"}
                position={"bottom"}
                parentTitle={true}
            >
                <span className="document-item__type-icon" title={props.attachment.name}>
                    <span className="document-item__file-extension">{extension}</span>
                </span>
            
                <span className="document-item__file-name">{props.attachment.name}</span>
            </Tooltip>
        </div>
    )
}