import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListView, ListViewHeader, ListViewItemProps } from "@progress/kendo-react-listview";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { AssetsExternalPageWidgetsEnumRm, IPortfolioObjectVM, LogTypeEnumRm } from "../../store/commonReducer";
import { ApplicationState } from "../../store/configureStore";
import { useLocation } from "react-router-dom";
import { LanguageSelector } from "../common/LanguageSelector";
import { resource, setHeaderShadowOnLoad } from "../../common/utils";
import { AssetSelectorHeaderSkeleton } from "./skeleton/AssetSelectorHeaderSkeleton";
import { Skeleton } from "@progress/kendo-react-indicators";
import { fetchLandingRmAction, navigationToMainPage } from "../../sagas/commonSagas";
import { AssetItemFooter } from "./AssetItemFooter";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faSearch);
library.add(faTimes);

import "../../styles/assetSelector.scss";
import { logUsage, logUsages } from "../../services/commonService";
import { ADD_NEW_SERVICE_REQUEST, ASSET_PAGE } from "../../constants";

export function AssetSelector() {
    const state = useSelector((state: ApplicationState) => state.common);
    const dispatch = useDispatch();

    const [assetList, setAssetList] = useState<IPortfolioObjectVM[]>([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const mainBackgroundColor = useSelector((state: ApplicationState) => state.common.landingRm.mainBackground);

    useEffect(() => {
        setIsLoading(true);

        setHeaderShadowOnLoad();

        const id = params.get("id");
        const langCode = params.get("langCode");

        if (id && (state.landingRm.portfolioObjects && state.landingRm.portfolioObjects.length === 1)) {
            dispatch(fetchLandingRmAction({ id: id, langCode: langCode }));
        }
    }, [])

    useEffect(() => {
        if (state.landingRm.portfolioObjects && state.landingRm.portfolioObjects.length > 1) {
            setAssetList(state.landingRm.portfolioObjects);
            setIsLoading(false);
        }
    }, [state.landingRm])

    const navigateToMainPage = async (asset: IPortfolioObjectVM) => {
        if (asset.widgets === AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink) {
            window.open(`${asset.externalServiceRequestUrl}&mainBackgroundColor=${encodeURIComponent(mainBackgroundColor)}`, '_blank', 'noreferrer');
            await logUsage(LogTypeEnumRm.PagePopup, ADD_NEW_SERVICE_REQUEST, 0);
        }
        else {
            dispatch(navigationToMainPage(asset));
            await logUsages([LogTypeEnumRm.ObjectID, LogTypeEnumRm.PageProcess], [asset.longCode, ASSET_PAGE], [asset.objectId, 0])
        }
    }

    const searchAssets = (event: InputChangeEvent) => {
        const assets = state.landingRm.portfolioObjects;

        if (event.value.length > 0) {
            const filteredAssets = assets.filter(a => a.longCode?.toLocaleLowerCase().includes(event.value.toLocaleLowerCase()));
            setAssetList(filteredAssets);
        } else {
            setAssetList([...assets]);
        }

        setSearchText(event.value);
    }

    const AssetSelectorHeader = () => {
        return (
            <ListViewHeader className={"m-sticky-header k-pos-sticky k-d-flex k-flex-nowrap k-align-items-start"} key={"header"}>
               
                {isLoading ?
                    <AssetSelectorHeaderSkeleton />
                    :
                    <>
                        <div className={"m-asset-header-wrapper k-pos-relative k-d-flex k-flex-row k-flex-wrap k-flex-1 k-align-items-start"}>
                            <h3
                                className={"m-asset-header k-pos-relative k-d-flex k-align-items-center"}
                                title={`${state.landingRm?.customerName} / ${state.landingRm?.portfolioName}`}>
                                {`${state.landingRm?.customerName} / ${state.landingRm?.portfolioName}`}
                            </h3>
                            <div className={"m-asset-search-wrapper k-pos-relative"}>
                                <Input
                                    style={{ paddingLeft: "36px", minWidth: "300px" }}
                                        id="assetSearch"
                                        key="searchInput"
                                        onChange={searchAssets}
                                        placeholder={resource("search-asset")}
                                        value={searchText}
                                        autoFocus={true}
                                        autoComplete={"false"}
                                />
                                <span className={"m-asset-search-wrapper__icon k-pos-absolute"}>
                                    <FontAwesomeIcon icon={faSearch} size="sm" />
                                </span>
                            </div>
                        </div>
                        <span className={"m-header__language k-pos-relative"} style={{padding: "10px 10px 10px 0"}}>
                            <LanguageSelector />
                        </span>
                    </>
                    }
            </ListViewHeader>
        )
    }

    const AssetItem = (props: ListViewItemProps) => {
        const asset: IPortfolioObjectVM = props.dataItem;
        let title = resource("view-asset-details");
        if (asset.widgets === AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink) {
            title = resource("service-request-add");
        }
        return (
            <Button
                onClick={() => navigateToMainPage(asset)}
                fillMode="flat"
                rounded={null}
                size="large"
                shape="rectangle"
                className="m-asset-selector__item">
                <span className="m-asset-selector__item-name" title={asset.longCode}>{asset.longCode}</span>
                <i className="drilldown" title={title}></i>
            </Button >
        );
    }

    const AssetSelectorFooter = () => {
        if (isLoading) {
            return (
                <AssetItemFooter class="is-loading">
                    <Skeleton shape={"text"} className="m-asset-selector__footer-skeleton" />
                </AssetItemFooter>
            )
        }
        else if (!isLoading && assetList.length === 0) {
            return (
                <AssetItemFooter class="no-results">
                    <span>{resource("common-no-data")}</span>
                </AssetItemFooter>
            )
        }
        else {
            return null;
        }
    }

    return (
        <Tooltip
            tooltipStyle={{
                maxWidth: '300px'
            }}
            showCallout={false}
            anchorElement={"target"}
            position={"bottom"}
            parentTitle={true}
        >
            <ListView
                data={assetList}
                header={AssetSelectorHeader}
                item={AssetItem}
                footer={AssetSelectorFooter}
                className="m-asset-selector k-rounded-md"
                style={{ height: "auto" }}
            />
        </Tooltip>
    )
}
