import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { getDocuments, getPreview, loadDocument } from "../services/documentService";
import { documentActions, IDocumentRM } from "../store/documentReducer";

export const fetchDocumentsAction = createAction<string>("FETCH_DOCUMENTS");
export const loadDocumentAction = createAction<{ id: string, mimeType: string }>("LOAD_DOCUMENT");
export const loadDocumentPreviewAction = createAction<string>("LOAD_PREVIEW");

export const DocumentSagas = {
    watchDocumentFetch: takeLatest(fetchDocumentsAction, documentsFetchAsync),
    watchDocumentLoad: takeLatest(loadDocumentAction, documentLoadAsync),
    watchDocumentPreviewLoad: takeLatest(loadDocumentPreviewAction, documentPreviewLoadAsync)
}

export function* documentsFetchAsync(action: PayloadAction<string>) {
    yield put(documentActions.setIsLoading(true));
    yield put(documentActions.setDocuments([]));

    const documents: IDocumentRM[] = yield call(getDocuments, action.payload);

    yield put(documentActions.setDocuments(documents));
    yield put(documentActions.setIsLoading(false));
}

export function* documentLoadAsync(action: PayloadAction<{ id: string, mimeType: string }>) {
    const documentResponse: Response = yield call(loadDocument, action.payload.id);

    let fileName = "";

    if (documentResponse.ok) {
        const contentDisposition = documentResponse.headers.get("content-disposition");
        const match = contentDisposition?.match("filename=?(.*);");
        fileName = match ? match[1] : "tiedosto";

        documentResponse.blob().then((blob) => {
            const mime = action.payload.mimeType;
            const mimeBlob = new Blob([blob], { type: mime })
            const blobUrl = window.URL.createObjectURL(mimeBlob);
            
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;

            fileName = fileName.replaceAll('"', '');
            downloadLink.download = fileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        })
    }
}

export function* documentPreviewLoadAsync(action: PayloadAction<string>) {
    yield put(documentActions.setIsLoading(true));

    const documentPreview: string = yield call(getPreview, action.payload);

    yield put(documentActions.setSelectedFilePreview(documentPreview));
    yield put(documentActions.setIsLoading(false));
}