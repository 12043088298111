import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { IServiceRequestVM, serviceRequestActions, ServiceRequestPhase } from "../../store/serviceRequestReducer";
import { ServiceRequestsRowButton } from "../shared/ServiceRequestRowButton";
import { logUsage } from "../../services/commonService";
import { LogTypeEnumRm } from "../../store/commonReducer";
import { SERVICE_REQUEST_DETAILS } from "../../constants";

interface ServiceRequestsRowProps {
    serviceRequest: IServiceRequestVM;
}

export function ServiceRequestsRow(props: ServiceRequestsRowProps) {
    const dispatch = useDispatch();

    const openDetails = async () => {
        dispatch(serviceRequestActions.setSelectedServiceRequest(props.serviceRequest));
        dispatch(push({ pathname: `/servicerequestdetails`, search: `${props.serviceRequest.encryptedUrl}` }));
        await logUsage(LogTypeEnumRm.PagePopup, SERVICE_REQUEST_DETAILS, props.serviceRequest.id);
    }

    return (
        <ServiceRequestsRowButton
            serviceRequestId={props.serviceRequest.id}
            description={props.serviceRequest.request}
            createdAt={props.serviceRequest.createdDate}
            status={ServiceRequestPhase[props.serviceRequest.phase].toString().toLowerCase()}
            statusClassName="phase"
            onClick={openDetails}
        />
    )
}
