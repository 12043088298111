import { Skeleton } from "@progress/kendo-react-indicators";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resource } from "../../common/utils";
import { fetchDocumentsAction, loadDocumentAction } from "../../sagas/documentSagas";
import { ApplicationState } from "../../store/configureStore";
import { IFileInfoRm } from "../../store/documentReducer";
import { DocumentAttachment } from "./DocumentAttachment";
import { DocumentImage } from "./DocumentImage";

import "../../styles/document.scss";

export function Documents() {
    const dispatch = useDispatch();
    const state = useSelector((state: ApplicationState) => state.documents);
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    useEffect(() => {
        if (id && state.documents.length === 0) {
            dispatch(fetchDocumentsAction(id));
        }
    }, [])

    const loadDocument = (item: IFileInfoRm) => {
        dispatch(loadDocumentAction({ id: item.idForPage, mimeType: item.mimeType}))
    }

    const openLinkInNewTab = (url: string) => {
        let urlLink = url;

        const pattern = /^((http|https):\/\/)/;

        if (!pattern.test(urlLink)) {
            urlLink = `http://${urlLink}`;
        }

        window.open(urlLink, "_blank");
    }

    return (
        <>
            <h4>{resource("documents")}</h4>
            <div className="documents">
                {state.isLoading && <h5><Skeleton shape={"text"} style={{ width: "25%", height: "32px" }} /></h5>}
                {!state.documents.length && !state.isLoading && <span>{resource("common-no-data")}</span>}
                {state.documents.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <h5>{item.name}</h5>
                            {item.files.map((file, i) => {
                                const hasPreview = file.mimeType.includes("image");

                                return (
                                    <React.Fragment key={i}>
                                        {hasPreview ?
                                            <DocumentImage document={file} key={i} loadDocument={() => loadDocument(file)} />
                                            :
                                            <DocumentAttachment key={i} attachment={file} onClick={() => loadDocument(file)} />
                                        }
                                    </React.Fragment>
                                );
                            })}

                            {item.links.map((link, i) => {
                                return (
                                    <DocumentAttachment key={i} attachment={link} onClick={() => openLinkInNewTab(link.url)} />
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    )
}