import { useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { INoteBoardNoteRM, noteBoardActions } from "../../store/noteboardReducer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { resource } from "../../common/utils";

library.add(faExclamationCircle);

interface INoteProps {
    note: INoteBoardNoteRM;
}

export function Note(props: INoteProps) {
    const dispatch = useDispatch();
    const intl = useInternationalization();
    const textRef = useRef<HTMLDivElement>(null);
    const [textHeight, setTextHeight] = useState(0);
    const maxTextHeight = 20;

    useEffect(() => {
        if (textRef.current) {
            const height = textRef.current.clientHeight;
            setTextHeight(height);
        }
    }, [textRef])

    const onNoteClick = (note: INoteBoardNoteRM) => {
        if (!note.isOpen && textHeight > maxTextHeight) {
            dispatch(noteBoardActions.updateNoteState(note));
        }
    }

    const noteClass = classNames({
        "note-board__note": true,
        "isHighlighted": props.note.isHighlighted,
        "is-open": props.note.isOpen,
        "is-collapsed": textHeight > maxTextHeight && !props.note.isOpen
    })

    return (
        <div className={noteClass} onClick={() => onNoteClick(props.note)}>
            <Tooltip
                tooltipStyle={{
                    maxWidth: '300px'
                }}
                showCallout={false}
                anchorElement={"target"}
                position={"bottom"}
                parentTitle={true}>
                <div className="note__container">
                    {props.note.isHighlighted && 
                        <span className="note__icon">
                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                        </span>}
                    <span className="note__header" title={props.note.header}>{props.note.header}</span>
                    <span className="note__date" title={intl.formatDate(new Date(props.note.createdDate), "d")}>
                        <span>{intl.formatDate(new Date(props.note.createdDate), "d")}</span>
                    </span>
                </div>
                <div className="note__text">
                    <span ref={textRef} >{props.note.note}</span>
                </div>
                {!props.note.isOpen && textHeight > maxTextHeight && <span className="note__read-more" title={resource("common-read-more")}>...</span>}
            </Tooltip>
        </div>
    );
}