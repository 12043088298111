import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IKeyValuePairVM } from "./commonReducer";

export enum ModuleServiceRequestStatus {
    New = 0,
    InProgress = 1,
    Closed = 2
}

export enum ModuleServiceReqestPhaseView {
    Open = 1,
    Closed = 2
}

export interface IModuleServiceRequestVM {
    id: number;
    description: string;
    status: ModuleServiceRequestStatus;
    location: string;
    serviceArea: IKeyValuePairVM;
    category: IKeyValuePairVM;
    createdAt: Date;
    encryptedUrl: string;
}

export interface ModuleServiceRequestState {
    moduleServiceRequests: IModuleServiceRequestVM[];
    moduleServiceRequestsView: IModuleServiceRequestVM[];
    selectedModuleServiceRequest: IModuleServiceRequestVM;
    selectedModuleAdditionalFields: ModuleServiceRequestAdditionalField[];
    selectedModuleComments: ModuleServiceRequestComment[];
    selectedView: ModuleServiceReqestPhaseView;
    isLoading: boolean;
}

export interface ModuleServiceRequestAdditionalField  {
    id: number;
    name: string;
    type: AdditionalFieldType;
    isEnabled: boolean;
    options: AdditionalFieldOption[];
    modifiedAt: Date;
    modifiedByName: string;
    serviceRequestId: number;
    textValue?: string;
    optionValue?: { id: number, name: string }[];
    categoryId: number | null;
}

export interface AdditionalFieldOption {
    id: number;
    name: string;
}

export enum AdditionalFieldType {
    Text = 0,
    MultiSelectOptions = 1
}

export enum EventType {
    Undefined = 0,
    ServiceRequestReOpened = 1,
    ServiceRequestStarted = 2,
    ServiceRequestClosed = 3
}

export interface ModuleServiceRequestComment {
    id: number;
    serviceRequestId: number;
    message: string;
    event: EventType;
    createdAt: Date;
}

const initialState: ModuleServiceRequestState = {
    moduleServiceRequests: [],
    moduleServiceRequestsView: [],
    selectedView: ModuleServiceReqestPhaseView.Open,
    selectedModuleServiceRequest: {} as IModuleServiceRequestVM,
    selectedModuleAdditionalFields: [],
    selectedModuleComments: [],
    isLoading: true
}

export const moduleServiceRequestSlice = createSlice({
    name: "moduleServiceRequest",
    initialState: initialState,
    reducers: {
        setModuleServiceRequests: (state, action: PayloadAction<IModuleServiceRequestVM[]>) => {
            state.moduleServiceRequests = action.payload;
        },
        setServiceRequestsByPhases: (state, action: PayloadAction<ModuleServiceRequestStatus[]>) => {
            const phases = action.payload;

            state.moduleServiceRequestsView = state.moduleServiceRequests?.filter(item => phases.includes(item.status))
            state.selectedView = phases.includes(ModuleServiceRequestStatus.Closed) ? ModuleServiceReqestPhaseView.Closed : ModuleServiceReqestPhaseView.Open
        },
        setSelectedModuleServiceRequest: (state, action: PayloadAction<IModuleServiceRequestVM>) => {
            state.selectedModuleServiceRequest = action.payload;
        },
        setSelectedModuleAdditionalFields: (state, action: PayloadAction<ModuleServiceRequestAdditionalField[]>) => {
            state.selectedModuleAdditionalFields = action.payload;
        },
        setSelectedModuleComments: (state, action: PayloadAction<ModuleServiceRequestComment[]>) => {
            state.selectedModuleComments = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        }
    }
})

export const moduleServiceRequestActions = moduleServiceRequestSlice.actions;

export default moduleServiceRequestSlice.reducer;