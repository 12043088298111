import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { ADD_NEW_SERVICE_REQUEST } from "../constants";
import { addLanguageSelection, getLandingRm, getLanguages, getObjectInformation, getObjectLandingRm, logUsage } from "../services/commonService";
import { AssetsExternalPageWidgetsEnumRm, commonActions, IAssetsExternalPageLandingRm, ILanguageVM, IObjectContactAndInfoRm, IPortfolioObjectVM, LogTypeEnumRm } from "../store/commonReducer";
import { documentActions } from "../store/documentReducer";
import { moduleServiceRequestActions } from "../store/moduleServiceRequestReducer";
import { noteBoardActions } from "../store/noteboardReducer";
import { serviceRequestActions } from "../store/serviceRequestReducer";

export const fetchLandingRmAction = createAction<{ id: string | null, langCode: string | null }>("FETCH_LANDING");
export const fetchObjectLandingRmAction = createAction<{ id: string | null, langCode: string | null }>("FETCH_OBJECTLANDING");
export const fetchLanguagesAction = createAction<{ id: string | null, langCode: string | null }>("FETCH_LANGUAGES");
export const fetchObjectInformationAction = createAction<string>("FETCH_OBJECTINFORMATION");
export const saveLanguageSelectionAction = createAction<ILanguageVM>("SAVE_LANGUAGE");
export const navigationToMainPage = createAction<IPortfolioObjectVM>("NAVIGATE_TO_MAINPAGE");

export const CommonSagas = {
    watchLandingRmFetch: takeLatest(fetchLandingRmAction, landingRmFetchAsync),
    watchObjectLandingRmFetch: takeLatest(fetchObjectLandingRmAction, objectLandingRmFetchAsync),
    watchLanguagesFetch: takeLatest(fetchLanguagesAction, languagesFetchAsync),
    watchObjectInformationFetch: takeLatest(fetchObjectInformationAction, objectInformationFetchAsync),
    watchLanguageSave: takeLatest(saveLanguageSelectionAction, languageSelectionSaveAction),
    watchNavigation: takeLatest(navigationToMainPage, mainPageNavigation)
}

export function* landingRmFetchAsync(action: PayloadAction<{ id: string | null, langCode: string | null }>) {
    if (!action.payload.id || !action.payload.langCode) {
        yield put(push("/error"));
        return;
    }

    const landingRM: IAssetsExternalPageLandingRm = yield call(getLandingRm, action.payload.id, action.payload.langCode);

    if (landingRM && landingRM.portfolioObjects.length === 1) {
        if (landingRM.widgets === AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink) {
            window.open(`${landingRM.portfolioObjects[0].externalServiceRequestUrl}&mainBackgroundColor=${landingRM.mainBackground}`, '_self');
            yield call(logUsage, LogTypeEnumRm.PagePopup, ADD_NEW_SERVICE_REQUEST, 0);
            return;
        }
        yield put(commonActions.setSelectedObject(landingRM.portfolioObjects[0]));
        yield put(push({ pathname: `/details`, search: `${landingRM.portfolioObjects[0].encryptedUrl}` }));
    }   

    landingRM.hasBeenLoaded = true;
    yield put(commonActions.setIsUsingServiceRequestModule(landingRM?.isServiceRequestModuleInUse /*false*/));
    yield put(commonActions.setLandingRm(landingRM));
}

export function* objectLandingRmFetchAsync(action: PayloadAction<{ id: string | null, langCode: string | null }>) {
    if (!action.payload.id || !action.payload.langCode) {
        yield put(push("/error"));
        return;
    }

    const landingRm: IAssetsExternalPageLandingRm = yield call(getObjectLandingRm, action.payload.id, action.payload.langCode);
    landingRm.hasBeenLoaded = true;
    yield put(commonActions.setIsUsingServiceRequestModule(landingRm?.isServiceRequestModuleInUse /*false*/));
    yield put(commonActions.setLandingRm(landingRm));
    yield put(commonActions.setSelectedObject(landingRm.portfolioObjects[0]));
}

export function* languagesFetchAsync(action: PayloadAction<{ id: string | null, langCode: string | null }>) {
    if (!action.payload.id || !action.payload.langCode) {
        yield put(push("/error"));
        return;
    }

    const languages: ILanguageVM[] = yield call(getLanguages, action.payload.id, action.payload.langCode);

    yield put(commonActions.setLanguages(languages));

    const selectedLanguages = languages.filter(l => l.isSelected);
    yield put(commonActions.setSelectedLanguage(selectedLanguages[0]));
}

export function* objectInformationFetchAsync(action: PayloadAction<string>) {
    yield put(commonActions.setObjectInfoIsLoading(true));
    yield put(commonActions.setObjectInformation({} as IObjectContactAndInfoRm));

    const objInfo: IObjectContactAndInfoRm = yield call(getObjectInformation, action.payload);

    yield put(commonActions.setObjectInformation(objInfo));
    yield put(commonActions.setObjectInfoIsLoading(false));
}

export function* languageSelectionSaveAction(action: PayloadAction<ILanguageVM>) {
    yield call(addLanguageSelection, action.payload.code);
    yield put(commonActions.setSelectedLanguage(action.payload));
}

export function* mainPageNavigation(action: PayloadAction<IPortfolioObjectVM>) {
    yield put(commonActions.setSelectedObject(action.payload));

    yield put(commonActions.setObjectInformation({} as IObjectContactAndInfoRm));
    yield put(documentActions.setDocuments([]));
    yield put(noteBoardActions.setNotes([]));
    yield put(serviceRequestActions.setServiceRequests([]));
    yield put(moduleServiceRequestActions.setModuleServiceRequests([]));

    yield put(push({ pathname: `/details`, search: action.payload.encryptedUrl }));
}