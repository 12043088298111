import i18next from "i18next";

export function getTextBrightness(colorString: string) {
    var vals = colorString.substring(colorString.indexOf('(') + 1, colorString.length - 1).split(', ');

    var red = Number(vals[0]);
    var green = Number(vals[2]);
    var blue = Number(vals[3]);

    const brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000;

    return brightness;
}

export function setHeaderShadowOnScroll(scrollPos: number) {
    const elem = document.querySelector(".m-sticky-header");
    if (elem && scrollPos > 32) {
        elem.classList.add("has-shadow");
    } else if (elem && scrollPos < 32) {
        elem.classList.remove("has-shadow");
    }
}

export function setHeaderShadowOnLoad() {
    const contentElem = document.querySelector(".m-page-view-wrapper");
    if (contentElem) {
        if (contentElem.scrollTop > 32) {
            document.querySelector(".m-sticky-header")?.classList.add("has-shadow");
        }
    }
}

export function resource(key: string) {
    return i18next.t(key);
}