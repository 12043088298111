import { ProgressBar } from "@progress/kendo-react-progressbars";
import React from "react";

export function Loading() {
    return (
        <svg className="circular">
            <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="4"
                strokeMiterlimit="10"
            />
        </svg>
    )
}

export function LoadingBar() {
    return (
        <ProgressBar value={null} />
    )
}