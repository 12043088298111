import React from "react";

interface IAssetItemFooterProps {
    children: any;
    class: string;
}

export function AssetItemFooter(props: IAssetItemFooterProps) {
    const classNameList = `m-asset-selector__item ${props.class}`;

    return (
        <div className={classNameList}>
            {props.children}
        </div>
    )
}