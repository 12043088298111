import { useInternationalization } from "@progress/kendo-react-intl";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchServiceReqestDetailsAction } from "../../sagas/serviceRequestSagas";
import { ApplicationState } from "../../store/configureStore";
import { ServiceRequestPhase } from "../../store/serviceRequestReducer";
import "../../styles/serviceRequest.scss";
import { ServiceRequestDetailsView } from "../shared/ServiceRequestDetailsView";
import { ServiceAreaField } from "../shared/ServiceAreaField";
import { CategoryField } from "../shared/CategoryField";
import { resource } from "../../common/utils";
import { SharedServiceRequestDetailsSkeleton } from "../shared/SharedServiceRequestDetailsSkeleton";

export function ServiceRequestDetails() {
    const state = useSelector((state: ApplicationState) => state.serviceRequest);
    const details = useSelector((state: ApplicationState) => state.serviceRequest.selectedServiceRequest);
    const intl = useInternationalization();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();

    useEffect(() => {
        const id = params.get("id");
        const langCode = params.get("langCode");

        if (!details.id && id) {
            dispatch(fetchServiceReqestDetailsAction({
                id: id,
                langCode: langCode
            }));
        }
    }, [])

    return (
        <ServiceRequestDetailsView
            serviceRequestId={details.id}
            description={details.request}
            additionalInfoFieldName={details.additionalInfoFieldName}
            location={details.codedObject?.name}
            createdAt={details.createdDate}
            status={ServiceRequestPhase[details.phase]?.toString().toLowerCase()}
            statusClassName="phase"
            isLoading={state.isLoading}
            serviceRequestDetailsSkeleton={<SharedServiceRequestDetailsSkeleton />}
        >
            <div className="details__classifications">
                <h4>
                    {resource("classifications")}
                </h4>
                <div className="classification-list">
                    <CategoryField name={details.adviceType?.name} categoryType={resource("advice-type")} />
                    <ServiceAreaField name={details.serviceArea?.name} />
                </div>
            </div>

        </ServiceRequestDetailsView>

    )
}