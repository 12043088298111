import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { getBackgroundColor, getExternalServiceRequestUrl, getModuleServiceRequestAdditionalFields, getModuleServiceRequestComments, getModuleServiceRequestDetails, getModuleServiceRequests } from "../services/moduleServiceRequestService";
import { commonActions } from "../store/commonReducer";
import { IModuleServiceRequestVM, moduleServiceRequestActions, ModuleServiceRequestAdditionalField, ModuleServiceRequestStatus, ModuleServiceRequestComment } from "../store/moduleServiceRequestReducer";

export const fetchModuleServiceRequestsAction = createAction<{ id: string, }>("FETCH_MODULESERVICEREQUESTS");
export const filterModuleServiceRequestsByPhaseAction = createAction<ModuleServiceRequestStatus[]>("SET_MODULESERVICEREQUESTSBYPHASE");
export const fetchModuleServiceReqestDetailsAction = createAction<{ id: string | null, langCode: string | null }>("FETCH_MODULESERVICEREQUESTDETAILS");
export const fetchModuleExternalServiceRequestUrlAction = createAction<{ id: string, langCode: number }>("FETCH_MODULEEXTERNALSERVICEREQUESTURL");

export const ModuleServiceRequestSagas = {
    watchModuleServiceRequestsFetch: takeLatest(fetchModuleServiceRequestsAction, moduleServiceRequestsFetchAsync),
    watchServiceRequestsWithPhaseFetch: takeLatest(filterModuleServiceRequestsByPhaseAction, moduleServiceRequestsFilterByPhase),
    watchModuleServiceRequestDetailsFetch: takeLatest(fetchModuleServiceReqestDetailsAction, moduleServiceRequestDetailsFetchAsync),
    watchModuleServiceRequestExternalServiceRequestUrlFetch: takeLatest(fetchModuleExternalServiceRequestUrlAction, moduleServiceRequestFetchExternalServiceRequestUrlAsync)
}

export function* moduleServiceRequestsFetchAsync(action: PayloadAction<{ id: string }>) {
    yield put(moduleServiceRequestActions.setIsLoading(true));
    yield put(moduleServiceRequestActions.setModuleServiceRequests([]));

    const serviceRequests: IModuleServiceRequestVM[] = yield call(getModuleServiceRequests, action.payload.id);

    yield put(moduleServiceRequestActions.setModuleServiceRequests(serviceRequests));
    yield put(moduleServiceRequestActions.setServiceRequestsByPhases([ModuleServiceRequestStatus.InProgress, ModuleServiceRequestStatus.New]))
    yield put(moduleServiceRequestActions.setIsLoading(false));
}

export function* moduleServiceRequestsFilterByPhase(action: PayloadAction<ModuleServiceRequestStatus[]>) {
    yield put(moduleServiceRequestActions.setServiceRequestsByPhases(action.payload))
}

export function* moduleServiceRequestDetailsFetchAsync(action: PayloadAction<{ id: string | null, langCode: string | null }>) {
    if (!action.payload.id || !action.payload.langCode) {
        yield put(push("/error"));
        return;
    }
    yield put(moduleServiceRequestActions.setIsLoading(true));
    yield put(moduleServiceRequestActions.setSelectedModuleServiceRequest({} as IModuleServiceRequestVM));

    const details: IModuleServiceRequestVM = yield call(getModuleServiceRequestDetails, action.payload.id, action.payload.langCode);
    const additionalFields: ModuleServiceRequestAdditionalField[] = yield call(getModuleServiceRequestAdditionalFields, action.payload.id, action.payload.langCode);
    const comments: ModuleServiceRequestComment[] = yield call(getModuleServiceRequestComments, action.payload.id, action.payload.langCode);
    const color: string = yield call(getBackgroundColor);

    yield put(commonActions.setMainBackground(color));
    yield put(moduleServiceRequestActions.setSelectedModuleServiceRequest(details));
    yield put(moduleServiceRequestActions.setSelectedModuleAdditionalFields(additionalFields));
    yield put(moduleServiceRequestActions.setSelectedModuleComments(comments));
    yield put(moduleServiceRequestActions.setIsLoading(false));
}

export function* moduleServiceRequestFetchExternalServiceRequestUrlAsync(action: PayloadAction<{ id: string, langCode: number }>) {
    yield put(moduleServiceRequestActions.setIsLoading(true));

    const externalServiceRequestUrl: string = yield call(getExternalServiceRequestUrl, action.payload.id, action.payload.langCode);

    yield put(commonActions.setExternalServiceRequestUrl(externalServiceRequestUrl));
    yield put(moduleServiceRequestActions.setIsLoading(false));
}