import { Skeleton } from "@progress/kendo-react-indicators";
import React from "react";
import { resource } from "../../common/utils";

export function SharedServiceRequestDetailsSkeleton() {
    return (
        <>
            <div className="details__header" style={{ marginBottom: "16px" }}>
                <span>
                    <Skeleton shape="text" style={{ width: "25%", height: "16px" }} />
                </span>
                <h2>
                    <Skeleton shape="text" style={{ width: "25%", height: "40px" }} />
                </h2>
                <span className="m-location" style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                    <Skeleton shape="circle" style={{ width: "16px", height: "16px", marginRight: "4px" }} />
                    <Skeleton shape="text" style={{ width: "calc(25% - 20px)", height: "20px" }} />
                </span>
            </div>
            <div className="details__state">
                <Skeleton shape="rectangle" style={{ width: "10%", height: "20px" }} />
            </div>

            <div className="details__classifications" style={{ marginTop: "16px" }} >
                <h4>
                    {resource("classifications")}
                </h4>
                <div className="classification-list">
                    <div className="classification-item">
                        <Skeleton shape="text" style={{ width: "10%", height: "20px" }} />
                        <Skeleton shape="text" style={{ width: "15%", height: "24px" }} />
                    </div>
                    <div className="classification-item">
                        <Skeleton shape="text" style={{ width: "10%", height: "20px" }} />
                        <Skeleton shape="text" style={{ width: "15%", height: "24px" }} />
                    </div>
                </div>
            </div>
        </>
    )
}