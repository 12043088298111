import { IAssetsExternalPageLandingRm, ILanguageVM, IObjectContactAndInfoRm, LogTypeEnumRm } from "../store/commonReducer"

export const getLandingRm = async (id: string, langCode: string): Promise<IAssetsExternalPageLandingRm> => {
    return await fetch(`userportfolio?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getObjectLandingRm = async (id: string, langCode: string): Promise<IAssetsExternalPageLandingRm> => {
    return await fetch(`userportfolio/objectLanding?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getLanguages = async (id: string, langCode: string): Promise<ILanguageVM[]> => {
    return await fetch(`system?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getObjectInformation = async (id: string): Promise<IObjectContactAndInfoRm> => {
    return await fetch(`object/contact-and-info?id=${id}`)
        .then(response => response.json())
        .then(data => data);
}

export const addLanguageSelection = async (code: string): Promise<void> => {
    await fetch(`system/saveLanguage/${code}`, { method: "POST" });
}

export const getApplicationTitle = async (): Promise<string> => {
    return await fetch(`system/getApplicationTitle`, { method: "GET" })
        .then(response => response.text())
        .then(data => data);
}

export const logUsage = async (logType: LogTypeEnumRm, selection: string, contentId: number): Promise<void> => {
    await fetch(`logging/log-usage?logType=${logType}&selection=${selection}&contentId=${contentId}`);
}

export const logUsages = async (logType: LogTypeEnumRm[], selection: string[], contentId: number[]): Promise<void> => {
    const requestBody = JSON.stringify({
        logTypes: logType,
        selections: selection,
        contentIds: contentId
    });

    await fetch(`logging/log-usages`, {
        method: "POST",
        body: requestBody,
        headers: { 'Content-Type': 'application/json' }
    });
}
