import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resource } from "../../common/utils";
import { loadDocumentPreviewAction } from "../../sagas/documentSagas";
import { ApplicationState } from "../../store/configureStore";
import { documentActions, IFileInfoRm } from "../../store/documentReducer";
import { Loading } from "../common/Loading";

interface DocumentImageProps {
    document: IFileInfoRm;
    loadDocument: () => void;
}

export function DocumentImage(props: DocumentImageProps) {
    const [showDialog, setShowDialog] = useState(false);
    const state = useSelector((state: ApplicationState) => state.documents);
    const dispatch = useDispatch();

    const toggleDialog = () => {
        if (showDialog) {
            dispatch(documentActions.setSelectedFilePreview(""));
        } else {
            dispatch(loadDocumentPreviewAction(props.document.idForPage));
        }
        setShowDialog(!showDialog);
    }

    return (
        <>
            <div className="document-item has-preview" onClick={toggleDialog} style={{ backgroundImage: `url(data:image/jpeg;base64,${props.document.thumbnail.content})` }}>
                <Tooltip
                    tooltipStyle={{
                        maxWidth: '300px'
                    }}
                    showCallout={false}
                    anchorElement={"target"}
                    position={"bottom"}
                    parentTitle={true}
                >
                    <img className="document-item__show-preview" title={props.document.name} alt="" />
                </Tooltip>
            </div>
            {showDialog &&
                <Tooltip
                    tooltipStyle={{
                        maxWidth: '300px'
                    }}
                    showCallout={false}
                    anchorElement={"target"}
                    position={"bottom"}
                    parentTitle={true}
                >
                    <dialog className="document-image-dialog" open>
                        <div className="document-image-dialog__nav-overlay"></div>
                        <div className="document-image-dialog__image-overlay"></div>
                    <div className="document-image-dialog__image">
                        {state.isLoading && <Loading />}
                        <img src={`data:image/jpeg;base64,${state.selectedFilePreview}`} alt="" />
                            <div className="document-image-dialog__header">
                                <span className="document-image-dialog__filename" title={props.document.name}>{props.document.name}</span>
                                <div className="document-image-dialog__actions">
                                    {props.document.idForPage && props.document.idForPage.length > 0 &&
                                        <span className="document-image-dialog__open" onClick={() => props.loadDocument()} title={resource("view_original_image")}></span>
                                    }
                                    <span className="document-image-dialog__close" onClick={toggleDialog} title={resource("common-close-preview")}></span>
                                </div>
                            </div>
                        </div>
                    </dialog>
                </Tooltip>
            }
        </>
    )
}