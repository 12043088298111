import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store/configureStore';
import rg4js from "raygun4js";
import { Config } from './config';

import "./styles/kendo/kendoAll.scss";
import "./styles/index.scss";


rg4js('apiKey', Config.raygunApiKey);
rg4js('enableCrashReporting', true);

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);