import { Skeleton } from "@progress/kendo-react-indicators";
import React from "react";

export function NoteBoardSkeleton() {
    return (
        <div className={"note-board__note"}>
            <div style={{ width: "100%" }}>
                <Skeleton shape={"text"} style={{ width: "25%", height: "24px" }} />
            </div>
            <div style={{ width: "100%" }}>
                <Skeleton shape={"text"} style={{ width: "50%", height: "20px" }} />
            </div>
        </div>
    )
}