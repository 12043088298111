import React from "react";
import CookieConsent from "react-cookie-consent";
import { useSelector } from "react-redux";
import { resource } from "../../common/utils";
import { ICommonState } from "../../store/commonReducer";
import { ApplicationState } from "../../store/configureStore";

export function CustomCookieConsent() {
    const state: ICommonState = useSelector((state: ApplicationState) => state.common);

    const getPolicyUrl = () => {
        const policyUrls = state.landingRm.privacyPolicyUrl;

        if (!policyUrls) {
            return "#";
        }

        const url = policyUrls.find(p => p.languageCodeId === state.selectedLanguage.id);
        return url?.url;
    }

    return (
        <CookieConsent
            containerClasses="cookie-consent-container"
            contentClasses="cookie-consent-content"
            style={{
                background: "var(--themeBackground)",
                color: "var(--themeColor)"
            }}
            buttonClasses="k-button k-primary submit-button cookie-consent-button"
            buttonText={resource("common-confirm")}
            buttonStyle={{
                background: "var(--themeColor)",
                color: "var(--themeBackground)",
                borderRadius: "4px",
                height: "48px",
                padding: "8px 16px"
            }}
        >
            <div className="cookie-consent-message">
                {resource("cookie-consent")}
            </div>
            <span>
                <a href={getPolicyUrl()} target="_blank" rel="noopener noreferrer">
                    {resource("cookie-consent-read-more")}
                </a>
            </span>
        </CookieConsent>
    )
}