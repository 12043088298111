import React from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { IModuleServiceRequestVM, ModuleServiceRequestStatus } from "../../store/moduleServiceRequestReducer";
import { ServiceRequestsRowButton } from "../shared/ServiceRequestRowButton";
import { logUsage } from "../../services/commonService";
import { LogTypeEnumRm } from "../../store/commonReducer";
import { SERVICE_REQUEST_DETAILS } from "../../constants";

interface ServiceRequestsRowProps {
    serviceRequest: IModuleServiceRequestVM;
}

export function ModuleServiceRequestsRow(props: ServiceRequestsRowProps) {
    const dispatch = useDispatch();

    const openDetails = async () => {
        dispatch(push({ pathname: `/servicerequestdetails`, search: `${props.serviceRequest.encryptedUrl}` }));
        await logUsage(LogTypeEnumRm.PagePopup, SERVICE_REQUEST_DETAILS, props.serviceRequest.id);
    }

    return (
        <ServiceRequestsRowButton
            serviceRequestId={props.serviceRequest.id}
            description={props.serviceRequest.description}
            createdAt={props.serviceRequest.createdAt}
            status={ModuleServiceRequestStatus[props.serviceRequest.status].toString().toLowerCase()}
            statusClassName="status"
            onClick={openDetails}
        />
    )
}
