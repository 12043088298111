import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IKeyValuePairVM {
    id: number;
    name: string;
}

export interface IPortfolioObjectVM {
    objectId: number;
    name: string;
    longCode: string;
    portfolioName: string;
    widgets: AssetsExternalPageWidgetsEnumRm;
    externalServiceRequestUrl: string;
    encryptedUrl: string;
}

export interface IPrivacyPolicyUrlRm {
    languageCodeId: number;
    url: string;
}

export interface IAssetsExternalPageLandingRm {
    portfolioObjects: IPortfolioObjectVM[];
    customerName: string;
    portfolioName: string;
    widgets: AssetsExternalPageWidgetsEnumRm;
    hasPortfolio: boolean;
    mainBackground: string;
    privacyPolicyUrl: IPrivacyPolicyUrlRm[];
    isServiceRequestModuleInUse: boolean;
    hasBeenLoaded: boolean;
    allowedRequestSource: AllowedRequestSource
}

export interface IPersonVM {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    jobTitle: string;
    company: string;
}

export interface IObjectContactAndInfoRm {
    persons: IPersonVM[];
    picture: any;
    address: string;
    mimeType: string;
}

export interface ILanguageVM {
    code: string;
    twoLetterISOName: string;
    nativeName: string;
    id: number;
    isRightToLeft: boolean;
    isSelected: boolean;
}

export enum AssetsExternalPageWidgetsEnumRm {
    None = 0,
    NoteBoardMessages = 1,
    LeaveServiceRequestLink = 2,
    Documents = 4,
    Contacts = 8,
    ServiceRequests = 16,
    Energy = 32,
    CurrentConditions = 64
}

export enum AllowedRequestSource {
    Undefined,
    OnlyExternalService,
    All
}

export enum LogTypeEnumRm {
    PageProcess = 0,
    ObjectID = 1,
    DatabaseID = 2,
    UserUpdateEmailSend = 3,
    UserCreateEmailSend = 4,
    UserForgotPasswordEmailSend = 5,
    Browser = 6,
    PageReport = 7,
    PagePopup = 8,
    PageNotKnown = 9,
    PageError = 10,
    MobilePage = 11,
    FileOpen = 17
}

export interface ICommonState {
    landingRm: IAssetsExternalPageLandingRm;
    selectedObject: IPortfolioObjectVM,
    objectInformation: IObjectContactAndInfoRm;
    languages: ILanguageVM[];
    selectedLanguage: ILanguageVM;
    isFetching: boolean;
    objectInfoIsLoading: boolean;
    externalServiceRequestUrl: string;
    isUsingServiceRequestModule: boolean;
    allowedRequestSource: AllowedRequestSource
}

const initialState: ICommonState = {
    landingRm: {} as IAssetsExternalPageLandingRm,
    selectedObject: {} as IPortfolioObjectVM,
    objectInformation: {} as IObjectContactAndInfoRm,
    languages: [],
    selectedLanguage: {} as ILanguageVM,
    isFetching: true,
    objectInfoIsLoading: true,
    externalServiceRequestUrl: "",
    isUsingServiceRequestModule: false,
    allowedRequestSource: AllowedRequestSource.Undefined
}

export const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        setLandingRm: (state, action: PayloadAction<IAssetsExternalPageLandingRm>) => {
            state.landingRm = action.payload;
        },
        setSelectedObject: (state, action: PayloadAction<IPortfolioObjectVM>) => {
            state.selectedObject = action.payload;
        },
        setObjectInformation: (state, action: PayloadAction<IObjectContactAndInfoRm>) => {
            state.objectInformation = action.payload;
        },
        setLanguages: (state, action: PayloadAction<ILanguageVM[]>) => {
            state.languages = action.payload;
        },
        setSelectedLanguage: (state, action: PayloadAction<ILanguageVM>) => {
            state.selectedLanguage = action.payload;
        },
        setIsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload;
        },
        setObjectInfoIsLoading: (state, action: PayloadAction<boolean>) => {
            state.objectInfoIsLoading = action.payload;
        },
        setMainBackground: (state, action: PayloadAction<string>) => {
            state.landingRm.mainBackground = action.payload;
        },
        setExternalServiceRequestUrl: (state, action: PayloadAction<string>) => {
            state.externalServiceRequestUrl = action.payload;
        },
        setIsUsingServiceRequestModule: (state, action: PayloadAction<boolean>) => {
            state.isUsingServiceRequestModule = action.payload;
        }
    }
});

export const commonActions = commonSlice.actions;

export default commonSlice.reducer; 
