import React from "react";
import { ErrorMessage } from "./ErrorMessage";
import { Loading } from "./Loading";

export enum FullScreenMessageType {
    Error,
    Loading
}

interface IFullScreenMessageProps {
    type: FullScreenMessageType;
}

export function FullScreenMessage(props: IFullScreenMessageProps) {
    return (
        <div className="fullscreen-layout">
            <div className="fullscreen-content">
                {props.type === FullScreenMessageType.Loading && <Loading />}
                {props.type === FullScreenMessageType.Error && <ErrorMessage />}
            </div>
        </div>
    )
}