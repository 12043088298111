import { useInternationalization } from "@progress/kendo-react-intl";
import React, { PropsWithChildren } from "react";
import { resource } from "../../common/utils";
import { Header } from "../common/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export interface ServiceRequestDetailsProps extends PropsWithChildren<any> {
    serviceRequestId: number;
    description: string;
    additionalInfoFieldName?: string;
    location: string;
    createdAt: Date;
    status: string;
    statusClassName: string;
    isLoading: boolean;
    serviceRequestDetailsSkeleton: React.ReactNode;
}

export function ServiceRequestDetailsView(props: ServiceRequestDetailsProps) {
    const intl = useInternationalization();
    
    return (
        <div className="details">
            <Header showBackButton={true} id={props.serviceRequestId} title={props.description} />
            <div className="m-page-view__content details">
                {props.isLoading ?
                    <> {props.serviceRequestDetailsSkeleton} </>
                    :
                    <>
                        <div className="details__header">
                            <span style={{
                                fontSize: "12px"
                            }}>
                                {props.additionalInfoFieldName ?
                                    `${resource("service-request-description")} [${props.additionalInfoFieldName}]`
                                    :
                                    `${resource("service-request-description")}`
                                }
                            </span>
                            <h2 style={{
                                lineHeight: "24px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 0"
                            }}>
                                {props.description}
                            </h2>
                            <span className="m-location">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <span
                                    className="m-location__text"
                                    style={{
                                        font: "normal normal bolder 13px/20px Roboto",
                                        marginLeft: "8px",
                                        textTransform: "uppercase"
                                    }}
                                >
                                    {
                                        props.location ?
                                        props.location
                                            : ""
                                    }
                                </span>
                            </span>
                        </div>
                        <div className="details__state">
                            <h3>
                                {
                                    props.status != undefined ?
                                        resource(`service-request-${props.statusClassName}-${props.status}`)
                                        : ""
                                }
                            </h3>
                            <span>
                                {
                                    props.createdAt ? resource("service-request-created")  + ": " +
                                        intl.formatDate(new Date(props.createdAt), "g")
                                        : ""
                                }
                            </span>
                        </div>
                        <div className="details__content">
                            {props.children}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}