import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchObjectInformationAction, fetchObjectLandingRmAction } from "../sagas/commonSagas";
import { AssetsExternalPageWidgetsEnumRm } from "../store/commonReducer";
import { ApplicationState } from "../store/configureStore";
import { ContactInformation } from "./contacts/ContactInformation";
import { Documents } from "./documents/Documents";
import { Header } from "./common/Header";
import { NoteBoard } from "./noteBoard/NoteBoard";
import { ServiceRequests } from "./serviceRequests/ServiceRequests";
import { ModuleServiceRequests } from "./moduleServiceRequests/ModuleServiceRequests";

export function Main() {
    const state = useSelector((state: ApplicationState) => state.common);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const id = params.get("id");
        const langCode = params.get("langCode");

        if (id && (!state.objectInformation.persons)) {
            dispatch(fetchObjectInformationAction(id));

        }

        if ((!state.selectedObject.objectId) && id) {
            dispatch(fetchObjectLandingRmAction({ id: id, langCode: langCode }));
        }
    }, []);

    useEffect(() => {
        const id = params.get("id");

        dispatch(fetchObjectLandingRmAction({ id: id, langCode: state.selectedLanguage.code }));
    }, [state.selectedLanguage])

    return (
        <div className="main__wrapper">
            <Header showBackButton={state.landingRm.hasPortfolio} />
            <div className="main__content">
                <div className="main__content-area">
                    <div className="main__title">
                        <h2>{state.selectedObject.longCode}</h2>
                        <span>{state.objectInformation ? state.objectInformation.address : ""}</span>
                    </div>
                    {(state.landingRm && state.landingRm.widgets && AssetsExternalPageWidgetsEnumRm.NoteBoardMessages === (state.landingRm.widgets & AssetsExternalPageWidgetsEnumRm.NoteBoardMessages)) === true &&
                        <NoteBoard />
                    }
                    {((state.landingRm && state.landingRm.widgets && AssetsExternalPageWidgetsEnumRm.ServiceRequests === (state.landingRm.widgets & AssetsExternalPageWidgetsEnumRm.ServiceRequests)) === true
                        || (state.landingRm && state.landingRm.widgets && AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink === (state.landingRm.widgets & AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink)) === true) &&
                        (state.isUsingServiceRequestModule
                        ? <ModuleServiceRequests />
                        : <ServiceRequests />)
                    }
                    {(state.landingRm && state.landingRm.widgets && AssetsExternalPageWidgetsEnumRm.Documents === (state.landingRm.widgets & AssetsExternalPageWidgetsEnumRm.Documents)) === true &&
                        <Documents />
                    }
                </div>
                {(state.landingRm && state.landingRm.widgets && AssetsExternalPageWidgetsEnumRm.Contacts === (state.landingRm.widgets & AssetsExternalPageWidgetsEnumRm.Contacts)) === true &&
                    <div className="main__sidebar">
                        <ContactInformation /> 
                    </div>
                }
            </div>
        </div>
    );
}
