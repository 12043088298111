import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface INoteBoardNoteRM {
    id: number;
    header: string;
    note: string;
    createdDate: Date;
    isHighlighted: boolean;
    isOpen: boolean;
}

export interface NoteBoardState {
    notes: INoteBoardNoteRM[];
    isLoading: boolean;
}

const initialState: NoteBoardState = {
    notes: [],
    isLoading: true
}

export const noteBoardSlice = createSlice({
    name: "noteboard",
    initialState: initialState,
    reducers: {
        setNotes: (state, action: PayloadAction<INoteBoardNoteRM[]>) => {
            state.notes = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        updateNoteState: (state, action: PayloadAction<INoteBoardNoteRM>) => {
            const noteIndex = state.notes.findIndex(x => x.id === action.payload.id);

            state.notes[noteIndex].isOpen = !state.notes[noteIndex].isOpen;
        }
    }
})

export const noteBoardActions = noteBoardSlice.actions;

export default noteBoardSlice.reducer;