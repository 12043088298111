import { goBack } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { ApplicationState } from "../../store/configureStore";
import { LanguageSelector } from "./LanguageSelector";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { resource } from "../../common/utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { logUsage } from "../../services/commonService";
import { LogTypeEnumRm } from "../../store/commonReducer";
import { ASSET_PAGE } from "../../constants";

interface IHeaderProps {
    showBackButton: boolean;
    id?: number;
    title?: string;
}

export function Header(props: IHeaderProps) {
    const dispatch = useDispatch();
    const state = useSelector((state: ApplicationState) => state.common);

    const navigateBack = async () => {
        if (location.pathname === "/servicerequestdetails")
            await logUsage(LogTypeEnumRm.PageProcess, ASSET_PAGE, 0);
        dispatch(goBack());
    }

    return (
        <div className="m-header m-sticky-header k-pos-sticky k-d-flex k-flex-nowrap k-align-items-center">
            {props.showBackButton &&
                <div className="m-header__back">
                    <Tooltip
                        showCallout={false}
                        anchorElement={"target"}
                        position={"bottom"}
                        parentTitle={true}
                    >
                        <Button
                            className="m-header__backbutton"
                            onClick={navigateBack}
                            fillMode="flat"
                            rounded={null}
                            title={resource("common-back")}
                        >
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                size="lg"
                            />
                        </Button>
                    </Tooltip>
                </div>
            }
            <div className="m-header__title">
                <span className="title__main">
                    {
                        props.id ?
                            `${resource("service-request-information")} | ID: ${props.id}`
                            : state.landingRm.customerName
                    }
                </span>
                <span
                    className="title__sticky"
                >
                    {props.id && props.title
                    ? props.title
                    : state.selectedObject.longCode }
                </span>
            </div>
            <div className="m-header__language k-pos-relative">
                <LanguageSelector />
            </div>
        </div>
    )
}