import React from "react";

interface CategoryFieldProps {
    name: string;
    categoryType: string;
}

export function CategoryField(props: CategoryFieldProps) {
    return (
        <div className="classification-item">
            <span className="classification-item__label">
                {props.categoryType}
            </span>
            <span className="classification-item__value">
                {
                    props.name ?
                        props.name
                        : ""
                }
            </span>
        </div>
    )
}