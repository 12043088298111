import { IModuleServiceRequestVM, ModuleServiceRequestAdditionalField, ModuleServiceRequestComment } from "../store/moduleServiceRequestReducer";

export const getModuleServiceRequests = async (id: string): Promise<IModuleServiceRequestVM[]> => {
    return await fetch(`moduleservicerequest/service-requests?id=${id}`)
        .then(response => response.json())
        .then(data => data);
}

export const getModuleServiceRequestDetails = async (id: string, langCode: string): Promise<IModuleServiceRequestVM> => {
    return await fetch(`moduleservicerequest?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getModuleServiceRequestAdditionalFields = async (id: string, langCode: string): Promise<ModuleServiceRequestAdditionalField[]> => {
    return await fetch(`moduleservicerequest/service-requests/additional-fields?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getModuleServiceRequestComments = async (id: string, langCode: string): Promise<ModuleServiceRequestComment[]> => {
    return await fetch(`moduleservicerequest/service-requests/comments?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getExternalServiceRequestUrl = async (id: string, langCode: number): Promise<string> => {
    return await fetch(`moduleservicerequest/external-service-request-url?id=${id}&langCode=${langCode}`)
        .then(response => response.text());
}

export const getBackgroundColor = async (): Promise<string> => {
    return await fetch("moduleservicerequest/get-background-color")
        .then(response => response.text())
        .then(data => data);
}