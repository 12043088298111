import { load } from '@progress/kendo-react-intl';

//import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
//import currencyData from 'cldr-core/supplemental/currencyData.json';
//import weekData from 'cldr-core/supplemental/weekData.json';

export default function internationalizationLoader(language: string) {
    let intlImport: Promise<any> = new Promise<any>(() => { });
    switch (language) {
        case "da-DK":
            intlImport = import(/* webpackChunkName: "internalization-da" */ './languages/da');
            break;
        case "de-DE":
            intlImport = import(/* webpackChunkName: "internalization-de" */ './languages/de');
            break;
        case "en-GB":
            intlImport = import(/* webpackChunkName: "internalization-en-GB" */ './languages/en-GB');
            break;
        case "et-EE":
            intlImport = import(/* webpackChunkName: "internalization-et" */ './languages/et');
            break;
        case "fi-FI":
            intlImport = import(/* webpackChunkName: "internalization-fi" */ './languages/fi');
            break;
        case "lt-LT":
            intlImport = import(/* webpackChunkName: "internalization-lt" */ './languages/lt');
            break;
        case "lv-LV":
            intlImport = import(/* webpackChunkName: "internalization-lv" */ './languages/lv');
            break;
        case "nb-NO":
            intlImport = import(/* webpackChunkName: "internalization-nb" */ './languages/nb-No');
            break;
        case "sv-SE":
            intlImport = import(/* webpackChunkName: "internalization-sv" */ './languages/sv');
            break;
        case "sv-FI":
            intlImport = import(/* webpackChunkName: "internalization-sv-FI" */ './languages/sv-FI');
            break;
        case "ru-RU":
            intlImport = import(/* webpackChunkName: "internalization-ru" */ './languages/ru');
            break;
    }

    intlImport.then(({ default: internalizationData }) => load(/*likelySubtags, currencyData, weekData,*/ ...internalizationData));

    return intlImport;
}