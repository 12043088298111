import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { resource } from "../../common/utils";
import { ApplicationState } from "../../store/configureStore";
import { CommentListItem } from "./CommentListItem";

const commentCount = 10;

export function CommentList() {
    const comments = useSelector((state: ApplicationState) => state.moduleServiceRequest.selectedModuleComments);

    const [showAllComments, setShowAllComments] = useState(false);

    const showAllCommentsClick = () => {
        setShowAllComments(true);
    };

    return (
        <>
            <h4>
                {resource("title-comments")}
            </h4>
            <div className={comments?.length ? "comment-list" : "comment-list is-empty"}>
            {
                (comments?.length > commentCount && !showAllComments) ?
                    <>
                        {comments.slice(0, commentCount).map(comment =>
                            <CommentListItem comment={comment} key={comment.id} />
                        )}
                        <div className="service-request-view__comments-action">
                            <Button
                                onClick={showAllCommentsClick}
                                className="m-showmore-button service-request-view__older-comments-button gm-densed"
                            >
                                {resource("view-older-comments")}
                            </Button>
                        </div>
                    </>
                    :
                    <>
                        {comments.map(comment =>
                            <CommentListItem comment={comment} key={comment.id} />
                        )}
                    </>
            }
            </div>
        </>
    )
}