import { IServiceRequestVM } from "../store/serviceRequestReducer";

export const getServiceRequests = async (id: string): Promise<IServiceRequestVM[]> => {
    return await fetch(`servicerequest/service-requests?id=${id}`)
        .then(response => response.json())
        .then(data => data);
}

export const getServiceRequestDetails = async (id: string, langCode: string): Promise<IServiceRequestVM> => {
    return await fetch(`serviceRequest?id=${id}&langCode=${langCode}`)
        .then(response => response.json())
        .then(data => data);
}

export const getExternalServiceRequestUrl = async (id: string, langCode: number): Promise<string> => {
    return await fetch(`serviceRequest/external-service-request-url?id=${id}&langCode=${langCode}`)
        .then(response => response.text());
}

export const getBackgroundColor = async (): Promise<string> => {
    return await fetch("serviceRequest/get-background-color")
        .then(response => response.text())
        .then(data => data);
}