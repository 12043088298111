import { Skeleton } from "@progress/kendo-react-indicators";
import React from "react";
import { resource } from "../../../common/utils";

export function AssetSelectorHeaderSkeleton() {
    return (
        <>
            <div className={"m-asset-header-wrapper k-pos-relative k-d-flex k-flex-row k-flex-wrap k-flex-1 k-align-items-start"}>
                <h3 className={"m-asset-header k-pos-relative k-d-flex k-align-items-center"}>
                    {resource("loading-assets")}
                </h3>
                <div className={"m-asset-search-wrapper k-pos-relative"}>
                    <Skeleton
                        shape={"text"}
                        style={{
                            width: "300px",
                            height: "36px",
                            transform: "none"
                        }}
                    />
                </div>
            </div>
            <span className={"m-header__language k-pos-relative"} style={{padding: "22px 16px"}}>
                <Skeleton
                    shape={"text"}
                    style={{
                        width: "96px",
                        height: "24px",
                        transform: "none"
                    }}
                />
            </span>
        </>
    )
}