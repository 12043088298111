import React, { PropsWithChildren } from "react"
import { resource } from "../../common/utils"
import classNames from "classnames";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { LoadingBar } from "../common/Loading";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store/configureStore";
import { ServiceReqestPhaseView } from "../../store/serviceRequestReducer";
import { ModuleServiceReqestPhaseView } from "../../store/moduleServiceRequestReducer";

export interface ServiceRequestsListViewProps extends PropsWithChildren<any> {
    isLoading: boolean,
    serviceRequestCount?: number,
}

export const ServiceRequestsListView = (props: ServiceRequestsListViewProps) => {
    const gridClassNames = classNames(
        "k-grid m-flex-datagrid",
        { "is-loading": props.isLoading }
    );
    const commonState = useSelector((state: ApplicationState) => state.common);
    const state = useSelector((state: ApplicationState) => commonState.isUsingServiceRequestModule ? state.moduleServiceRequest : state.serviceRequest);

    return (
        <Tooltip
            tooltipStyle={{
                maxWidth: '300px'
            }}
            showCallout={false}
            anchorElement={"target"}
            position={"bottom"}
            parentTitle={true}
        >
            <div className="m-grid-wrapper m-clean-grid m-densed">
                <div className={gridClassNames}>
                    <div className="m-flex-datagrid-header">
                        {props.isLoading &&
                            <LoadingBar />
                        }
                        <div className="m-flex-datagrid-header-item">
                            <span title={resource('service-request-description')}>
                                {resource('service-request-description')}
                            </span>
                            {(state.selectedView === ServiceReqestPhaseView.Done || state.selectedView === ModuleServiceReqestPhaseView.Closed) &&
                                <span>
                                    {resource("service-request-done-time-limit")}
                                </span>
                            }
                        </div>
                    </div>
                    {props.isLoading &&
                        <div className="m-flex-datagrid-row is-loading">
                            <div
                                className="m-flex-datagrid-item"
                                style={{
                                    borderBottom: "0",
                                    borderTop: "0"
                                }}>
                                <span>{resource("service-request-loading")}</span>
                            </div>
                        </div>
                    }
                    {props.serviceRequestCount === 0 && !props.isLoading &&
                        <div className="m-flex-datagrid-row is-empty">
                            <div className="m-flex-datagrid-item">
                                <span className="cell-content-text">
                                    {resource("common-no-data")}
                                </span>
                            </div>
                        </div>
                    }
                    <>
                        {props.children}
                    </>
                </div>
            </div>
        </Tooltip>
    )
}