import { Ripple } from "@progress/kendo-react-ripple";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setHeaderShadowOnScroll, getTextBrightness  } from "../common/utils";
import { fetchLandingRmAction, fetchLanguagesAction, fetchObjectLandingRmAction } from "../sagas/commonSagas";
import { ApplicationState } from "../store/configureStore";
import { AssetSelector } from "./assetSelector/AssetSelector";
import { CustomCookieConsent } from "./common/CustomCookieConsent";
import { FullScreenMessage, FullScreenMessageType } from "./common/FullScreenMessage";
import { Localization } from "./Localization";
import { Main } from "./Main";
import { ModuleServiceRequestDetails } from "./moduleServiceRequests/ModuleServiceRequestDetails";
import { ServiceRequestDetails } from "./serviceRequests/ServiceRequestDetails";

export enum IPageEnum {
    AssetSelector,
    Main,
    ServiceRequestDetails
}

interface IPageProps {
    page: IPageEnum;
}

export function Page(props: IPageProps) {
    const state = useSelector((state: ApplicationState) => state.common);
    const location = useLocation();
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const langCode = params.get("langCode");
    const shouldFetchLandingRm = id && (props.page === IPageEnum.AssetSelector || props.page === IPageEnum.ServiceRequestDetails);
    
    useEffect(() => {
        if (state.languages.length === 0) {
            dispatch(fetchLanguagesAction({ id: id, langCode: langCode }))
        }
    }, [state.languages])

    useEffect(() => {
        if (shouldFetchLandingRm && props.page === IPageEnum.AssetSelector) {
            dispatch(fetchLandingRmAction({ id: id, langCode: langCode }));
        }
        else if (shouldFetchLandingRm && props.page === IPageEnum.ServiceRequestDetails) {
            dispatch(fetchObjectLandingRmAction({ id: id, langCode: langCode }));
        }
    }, [])

    const scrollHandler = (e) => {
        setHeaderShadowOnScroll(e.currentTarget.scrollTop);
    }

    const themeBackground = state.landingRm.mainBackground ?? "rgba(255, 255, 255, 1)";
    const themeColor = getTextBrightness(themeBackground) > 180 ? '#000000' : '#ffffff';
    const themeBrightness = getTextBrightness(themeBackground).toString();
    const themeBorderColor = getTextBrightness(themeBackground) < 180 ? themeBackground : 'rgba(0,0,0, .87)';

    document.body.style.setProperty("--themeBackground", themeBackground);
    document.body.style.setProperty("--themeColor", themeColor);
    document.body.style.setProperty("--themeBrightness", themeBrightness);
    document.body.style.setProperty("--themeBorderColor", themeBorderColor);

    return (
        <>
            <Localization>
                <>
                    {state.isFetching || (shouldFetchLandingRm && !(state.landingRm.hasBeenLoaded))?
                        <FullScreenMessage type={FullScreenMessageType.Loading} />
                        :
                        <div className="body-content k-d-flex">
                            <div className="body-content-area k-pos-relative k-d-flex">
                                <div className="m-page-view k-pos-absolute k-d-flex k-flex-column m-scrollbar" onScroll={scrollHandler}>
                                        <Ripple className={"m-page-view-wrapper k-rounded-md k-pos-relative k-d-flex k-flex-column"}>
                                            {props.page === IPageEnum.AssetSelector && <AssetSelector />}
                                            {props.page === IPageEnum.Main && <Main />}
                                            {props.page === IPageEnum.ServiceRequestDetails &&
                                                (state.isUsingServiceRequestModule 
                                                ? <ModuleServiceRequestDetails /> 
                                                : <ServiceRequestDetails />)}
                                        </Ripple>
                                </div>
                            </div>
                            <CustomCookieConsent />
                        </div>
                    }
                </>
            </Localization>
        </>
    )
}