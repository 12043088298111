import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { getNotes } from "../services/noteBoardService";
import { INoteBoardNoteRM, noteBoardActions } from "../store/noteboardReducer";

export const fetchNotesAction = createAction<string>("FETCH_NOTES");

export const NoteBoardSagas = {
    watchNotesFetch: takeLatest(fetchNotesAction, notesFetchAsync)
}

export function* notesFetchAsync(action: PayloadAction<string>) {
    yield put(noteBoardActions.setIsLoading(true));
    yield put(noteBoardActions.setNotes([]));

    const notes: INoteBoardNoteRM[] = yield call(getNotes, action.payload);

    yield put(noteBoardActions.setNotes(notes));
    yield put(noteBoardActions.setIsLoading(false));
}