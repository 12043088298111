import { Skeleton } from "@progress/kendo-react-indicators";
import React from "react";

export function ContactInformationSkeleton() {
    return (
        <div className="contact-information__person">
            <span className="person__title">
                <Skeleton shape="text" style={{ height: "28px", width: "25%" }} />
            </span>
            <span>
                <Skeleton shape="text" style={{ height: "24px", width: "50%" }} />
            </span>
            <span className="person__email">
                <Skeleton shape="text" style={{ height: "24px", width: "50%" }} />
            </span>
        </div>
    )
}