import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchExternalServiceRequestUrlAction, fetchServiceRequestsAction } from "../../sagas/serviceRequestSagas";
import { AssetsExternalPageWidgetsEnumRm, LogTypeEnumRm } from "../../store/commonReducer";
import { ApplicationState } from "../../store/configureStore";
import { ServiceRequestsList } from "./ServiceRequestsList";

import "../../styles/serviceRequest.scss";
import { ServiceRequestsView } from "../shared/ServiceRequestsView";
import { ServiceRequestPhase } from "../../store/serviceRequestReducer";
import { logUsage } from "../../services/commonService";
import { ADD_NEW_SERVICE_REQUEST } from "../../constants";

export function ServiceRequests(props) {
    const dispatch = useDispatch();
    const state = useSelector((state: ApplicationState) => state.serviceRequest);
    const externalServiceRequestUrl = useSelector((state: ApplicationState) => state.common.externalServiceRequestUrl);
    const selectedLanguage = useSelector((state: ApplicationState) => state.common.selectedLanguage);
    const widgets: AssetsExternalPageWidgetsEnumRm = useSelector((state: ApplicationState) => state.common.landingRm.widgets);
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const mainBackgroundColor = useSelector((state: ApplicationState) => state.common.landingRm.mainBackground);

    useEffect(() => {
        if (id && state.serviceRequests.length === 0) {
            dispatch(fetchServiceRequestsAction(id));
        }
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(fetchExternalServiceRequestUrlAction({ id: id, langCode: selectedLanguage.id }));
        }
    }, [selectedLanguage.code])

    const openExternalServiceRequestPage = async (url?: string) => {
        if (url && url.length > 0) {
            window.open(`${url}&mainBackgroundColor=${encodeURIComponent(mainBackgroundColor)}`, "_blank");
            await logUsage(LogTypeEnumRm.PagePopup, ADD_NEW_SERVICE_REQUEST, 0);
        }
    }

    // HUOM! AssetsExternalPageWidgetsEnumRm on bit-tyyppinen enumeraatio ja sen vertailu ts-koodissa pitää tehdä poikkeavasti verrattuna 
    // normaaliin enum-vertailuun. 
    // HUOM2! jälkimmäisessä osassa pitää olla vain yksi &-merkki jos siihen laittaa kaksi niin vertailu ei enää toimi
    const serviceRequestAccess = (widgets && AssetsExternalPageWidgetsEnumRm.ServiceRequests === (widgets & AssetsExternalPageWidgetsEnumRm.ServiceRequests)) === true;
    const leaveNewServiceRequest = (widgets && AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink === (widgets & AssetsExternalPageWidgetsEnumRm.LeaveServiceRequestLink)) === true;
    const stateOpenCount = state.serviceRequests?.filter(item => item.phase < ServiceRequestPhase.Done).length
    const stateReadyCount = state.serviceRequests?.filter(item => item.phase === ServiceRequestPhase.Done).length

    return (
        <ServiceRequestsView
            leaveNewServiceRequest={leaveNewServiceRequest}
            serviceRequestAccess={serviceRequestAccess}
            isLoading={state.isLoading}
            serviceRequestOpenCount={stateOpenCount}
            serviceRequestReadyCount={stateReadyCount}
            onExternalServiceRequestPageClick={() => openExternalServiceRequestPage(externalServiceRequestUrl)} >
            <ServiceRequestsList />
        </ServiceRequestsView>
    )
}