import { IDocumentRM } from "../store/documentReducer";

export const getDocuments = async (id: string): Promise<IDocumentRM[]> => {
    return await fetch(`document?id=${id}`)
        .then(response => response.json())
        .then(data => data);
}

export const loadDocument = async (fileId: string): Promise<Response> => {
    return await fetch(`document/download?fileId=${fileId}`)
        .then(response => response);
}

export const getPreview = async (fileId: string): Promise<string> => {
    return await fetch(`document/preview?fileId=${fileId}`)
        .then(response => response.text())
        .then(data => data);
}