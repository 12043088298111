import { Button } from "@progress/kendo-react-buttons";
import { useInternationalization } from "@progress/kendo-react-intl";
import React from "react";
import { resource } from "../../common/utils";

interface ServiceRequestsRowButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    serviceRequestId: number;
    description: string;
    createdAt: Date;
    status: string;
    statusClassName: string;
}

export function ServiceRequestsRowButton(props: ServiceRequestsRowButtonProps) {
    const intl = useInternationalization();
    
    const statusString = resource(`service-request-${props.statusClassName}-${props.status}`)

    return (
        <Button
            className={`m-flex-datagrid-row ${props.status}`}
            key={props.serviceRequestId}
            title={resource("view-request-details")}
            fillMode="flat"
            rounded={null}
            size="large"
            shape="rectangle"
            onClick={props.onClick}
        >
            <div className="m-flex-datagrid-item m-flex-datagrid-item-description">
                <span className="cell-content-text" title={props.description}>{props.description}</span>
            </div>
            <div className={`m-flex-datagrid-item m-flex-datagrid-item-${props.statusClassName}`}>
                <span className="cell-content-text">{statusString}</span>
            </div>
            <div className="m-flex-datagrid-item m-flex-datagrid-item-timestamp">
                <span className="cell-content-text">{intl.formatDate(new Date(props.createdAt), "d")}</span>
            </div>
        </Button>
    )
}