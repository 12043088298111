import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchNotesAction } from "../../sagas/noteBoardSagas";
import { ApplicationState } from "../../store/configureStore";
import { NoteBoardSkeleton } from "./skeleton/NoteBoardSkeleton";
import "../../styles/noteboard.scss";
import { Note } from "./Note";

export function NoteBoard() {
    const state = useSelector((state: ApplicationState) => state.noteboard);
    const dispatch = useDispatch();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    useEffect(() => {
        if (id && state.notes.length === 0) {
            dispatch(fetchNotesAction(id));
        }
    }, [])

    return (
        <div className="note-board">
            {state.isLoading && <NoteBoardSkeleton />}
            {state.notes.map((note, index) => <Note key={index} note={note} /> )}
        </div>
    )
}