import React from "react";
import { faFrown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resource } from "../../common/utils";

export function ErrorMessage() {
    const getResourceText = (key: string, text: string) => {
        if (resource(key) === key) {
            return text;
        }

        return resource(key);
    }

    return (
        <>
            <FontAwesomeIcon icon={faFrown} size={"10x"} />
            <div className="fullscreen-message">
                <h1>{getResourceText("common-error-msg-title", "Page not found")}</h1>
                <span className="message">{getResourceText("common-error-msg", "If the error recurs, please contact your Mainuser")}</span>
            </div>
        </>
    )
}