import { useInternationalization } from "@progress/kendo-react-intl";
import classNames from "classnames";
import React from "react";
import { resource } from "../../common/utils";
import { EventType, ModuleServiceRequestComment } from "../../store/moduleServiceRequestReducer";

export interface ICommentItemProps {
    comment: ModuleServiceRequestComment;
}

export function CommentListItem({ comment }: ICommentItemProps) {
    const intlService = useInternationalization();

    const eventKey = getStatusTitle(comment.event);

    return <div className="comment-block">
        <span className="comment-message">
            <span className="comment-message-container">
                {
                    comment.event > 0 ?
                        <span className={"comment-event-type"}>{resource(eventKey)}</span> :
                        null
                }

                {comment.message}
            </span>
            <span className="gm-info-text comment-timestamp">
                <span className="comment-datetime">
                    {
                        intlService.formatDate(new Date(comment.createdAt), "g")
                    }
                </span>
            </span>
        </span>    
    </div>
}

export function getStatusTitle(status: EventType): string {
    const item = statusData.find((item) => {
        return item.value === status;
    });
    return item
        ? item.key
        : "undefined";
};

const statusData = [
    {
        key: "service-request-status-new",
        value: EventType.ServiceRequestReOpened
    },
    {
        key: "service-request-status-inprogress",
        value: EventType.ServiceRequestStarted
    },
    {
        key: "service-request-status-closed",
        value: EventType.ServiceRequestClosed
    }
];