import React from "react";

export function ClassificationItem(props: { label: string, value: string | undefined }) {
    return (
        <div className="classification-item gm-layout-cell-6">
            <span className="classification-item__label">
                {props.label}
            </span>
            <span className="classification-item__value">
                {props.value ?? ''}
            </span>
        </div>
    );
}