import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IPageEnum, Page } from './components/Page';
import { FullScreenMessage, FullScreenMessageType } from './components/common/FullScreenMessage';
import { getApplicationTitle } from './services/commonService';
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

export default function App() {
    useEffect(() => {
        async function fetchApplicationTitle() {
            const response = await getApplicationTitle();
            document.title = response;
        }

        fetchApplicationTitle();
    }, []);

    return (
        <Switch>
            <Route path="/assets" render={() => <Page page={IPageEnum.AssetSelector} />} />
            <Route path="/details" render={() => <Page page={IPageEnum.Main} />} />
            <Route path="/servicerequestdetails" render={() => <Page page={IPageEnum.ServiceRequestDetails} />} />
            <Route path="/error" render={() => <FullScreenMessage type={FullScreenMessageType.Error} />} />
            <Route path="*" render={() => <FullScreenMessage type={FullScreenMessageType.Error} />} />
        </Switch>
    )
}
