import { connectRouter, push, routerMiddleware } from "connected-react-router";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../sagas/rootSaga";
import commonReducer, { ICommonState } from "./commonReducer";
import documentReducer, { DocumentState } from "./documentReducer";
import noteboardReducer, { NoteBoardState } from "./noteboardReducer";
import serviceRequestReducer, { ServiceRequestState } from "./serviceRequestReducer";
import * as rg4js from "raygun4js";
import moduleServiceRequestReducer, { ModuleServiceRequestState } from "./moduleServiceRequestReducer";

declare var window: any;

export default function configureStore(history) {
    const rootReducers = combineReducers({
        common: commonReducer,
        noteboard: noteboardReducer,
        serviceRequest: serviceRequestReducer,
        moduleServiceRequest: moduleServiceRequestReducer,
        router: connectRouter(history),
        documents: documentReducer
    });

    const sagaMiddleware = createSagaMiddleware({
        onError: (error, info) => {
            console.error(error, [info]);
            rg4js('send', {
                error: error,
                customData: [{ info: info }]
            });
            store.dispatch(push("/error"));
        }
    });

    const middleware = [
        routerMiddleware(history),
        sagaMiddleware
    ];

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducers,
        composeEnhancers(applyMiddleware(...middleware))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}

export interface ApplicationState {
    common: ICommonState,
    noteboard: NoteBoardState,
    serviceRequest: ServiceRequestState,
    moduleServiceRequest: ModuleServiceRequestState,
    documents: DocumentState
}