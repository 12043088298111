import React from "react";
import { resource } from "../../common/utils";

interface ServiceAreaFieldProps {
    name: string;
}

export function ServiceAreaField(props: ServiceAreaFieldProps) {
    return (
        <div className="classification-item">
            <label className="classification-item__label">
                {resource("service-area")}
            </label>
            <span className="classification-item__value">
                {
                    props.name ?
                        props.name
                        : ""
                }
            </span>
        </div>
    )
}