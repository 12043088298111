import { DropDownButton, DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonActions, ILanguageVM } from "../../store/commonReducer";
import { ApplicationState } from "../../store/configureStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fetchLandingRmAction, saveLanguageSelectionAction } from "../../sagas/commonSagas";
import { Tooltip } from "@progress/kendo-react-tooltip";

library.add(faGlobe);

export function LanguageSelector() {
    const state = useSelector((state: ApplicationState) => state.common);
    const dispatch = useDispatch();
    const params = new URLSearchParams(location.search);

    const onLanguageClick = (e: DropDownButtonItemClickEvent) => {
        if (state.selectedLanguage.code !== e.item.code) {
            dispatch(commonActions.setIsFetching(true));
            dispatch(saveLanguageSelectionAction(e.item));
            const id = params.get("id");
            dispatch(fetchLandingRmAction({ id: id, langCode: e.item.code }));
        }
    }

    //set items icon, text and kendo states "selected" "disabled" for current language
    const languageItems = state.languages.map((language: ILanguageVM) => {
        const isSelectedLanguage = state.selectedLanguage.code === language.code;
        return {
            ...language,
            icon: isSelectedLanguage
                ? "check"
                : " ",
            text: language.nativeName,
            selected: isSelectedLanguage,
            disabled: isSelectedLanguage,
            code: language.code
        }
    });

    return (
        <Tooltip
            showCallout={false}
            anchorElement={"target"}
            position={"bottom"}
            parentTitle={true}
            style={{ zIndex: 101 }}
        >
            <div className="language-button-wrapper" title={state.selectedLanguage.nativeName}>
                <FontAwesomeIcon icon={faGlobe} size="lg" className={"k-d-inline-flex k-align-items-center"} />
                <DropDownButton
                    text={state.selectedLanguage.twoLetterISOName}
                    items={languageItems}
                    onItemClick={(e) => onLanguageClick(e)}
                    fillMode="flat"
                    rounded="small"
                    shape="rectangle"
                    size="large"
                    popupSettings={{
                        anchorAlign: {
                            horizontal: "right",
                            vertical: "bottom"
                        },
                        popupAlign: {
                            horizontal: "right",
                            vertical: "top"
                        }
                    }}
                />
            </div>
        </Tooltip>
    )
}