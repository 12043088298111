import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resource } from "../../common/utils";
import { fetchModuleServiceReqestDetailsAction } from "../../sagas/moduleServiceRequestSagas";
import { ApplicationState } from "../../store/configureStore";
import { ModuleServiceRequestStatus } from "../../store/moduleServiceRequestReducer";
import "../../styles/moduleServiceRequest.scss";
import { ClassificationItemAdditionalField } from "./ClassificationItemAdditionalField";
import { ServiceRequestDetailsView } from "../shared/ServiceRequestDetailsView";
import { CategoryField } from "../shared/CategoryField";
import { SharedServiceRequestDetailsSkeleton } from "../shared/SharedServiceRequestDetailsSkeleton";
import { CommentList } from "./CommentList";

export function ModuleServiceRequestDetails() {
    const state = useSelector((state: ApplicationState) => state.moduleServiceRequest);
    const details = useSelector((state: ApplicationState) => state.moduleServiceRequest.selectedModuleServiceRequest);
    const additionalFields = useSelector((state: ApplicationState) => state.moduleServiceRequest.selectedModuleAdditionalFields);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();

    useEffect(() => {
        const id = params.get("id");
        const langCode = params.get("langCode");

        if (id) {
            dispatch(fetchModuleServiceReqestDetailsAction({
                id: id,
                langCode: langCode
            }));
        }
    }, [])
    
    const additionalFieldClassificationItems = additionalFields.map(additionalField => <ClassificationItemAdditionalField key={additionalField.id} additionalField={additionalField} />);

    return (
        <ServiceRequestDetailsView
            serviceRequestId={details.id}
            description={details.description}
            location={details.location}
            createdAt={details.createdAt}
            status={ModuleServiceRequestStatus[details.status]?.toString().toLowerCase()}
            statusClassName="status"
            isLoading={state.isLoading}
            serviceRequestDetailsSkeleton={<SharedServiceRequestDetailsSkeleton />}
        >
            <div className="details__classifications">
                <h4>
                    {resource("classifications")}
                </h4>
                <div className="classification-list">
                    <CategoryField name={details.category?.name} categoryType={resource("category-type")} />

                    <div>
                        {additionalFields.length > 0
                            ? <>
                                <h4>
                                    {resource('title-additional-fields')}
                                </h4>
                                <div className="classification-list">
                                    {additionalFieldClassificationItems}
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
            <div className="details__comments">
                <CommentList />
            </div>
        </ServiceRequestDetailsView>
    )
}